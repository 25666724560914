<template>
  <div id="dashboard-page">
    <h1 id="page-title" class="h2">Patiënten</h1>

    <div class="row">
      <div class="col">
        <div class="card">
          <div class="card-body">
            <table id="patient-overview-table" class="table">
              <thead>
                <tr>
                  <th>#</th>
                  <th>Naam</th>
                  <th>E-mailadres</th>
                  <th>Dataverzoek</th>
                  <th class="no-sort"></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="user in users" :key="user.id" :set="mockStatusNum = mockStatus()">
                  <td>{{ user.id }}</td>
                  <td>{{ user.name }}</td>
                  <td>{{ user.email }}</td>
                  <td v-if="mockStatusNum === 0" class="text-success">
                    <span class="dot bg-success"></span> Ingewilligd
                  </td>
                  <td v-else-if="mockStatusNum === 1" class="text-warning">
                    <span class="dot bg-warning"></span> Verzonden
                  </td>
                  <td v-else class="text-danger">
                    <span class="dot bg-danger"></span> Geweigerd
                  </td>
                  <td>
                    <div class="btn-group">
                      <router-link :to="{ name: 'patient', params: { patientId: user.id }}" class="btn btn-light rounded-0 text-capitalize btn-sm font-weight-normal" role="button"><font-awesome-icon :icon="['far', 'edit']"/>  details</router-link>
                      <button type="button" class="btn btn-light dropdown-toggle dropdown-toggle-split rounded-0 btn-sm" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <span class="sr-only">Toggle Dropdown</span>
                      </button>
                      <div class="dropdown-menu">
                        <a class="dropdown-item" href="#">Action</a>
                        <a class="dropdown-item" href="#">Another action</a>
                        <a class="dropdown-item" href="#">Something else here</a>
                        <div class="dropdown-divider"></div>
                        <a class="dropdown-item" href="#">Separated link</a>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import 'datatables.net'
import 'datatables.net-bs4'

export default {
  name: 'PatientOverview',
  data () {
    return {
      isLoading: false,
      users: []
    }
  },
  mounted () {
    this.fetchUsers().then(() => {
      $('#patient-overview-table').DataTable({
        language: { url: '//cdn.datatables.net/plug-ins/1.10.24/i18n/Dutch.json' },
        columnDefs: [
          { targets: 'no-sort', orderable: false, className: 'dt-right' }
        ]
      })
    })
  },
  methods: {
    fetchUsers () {
      return this.$http
        .get('//jsonplaceholder.typicode.com/users')
        .then((response) => {
          this.users = response.data
        })
        .catch(error => console.log(error))
    },
    mockStatus () {
      return Math.floor(Math.random() * 3)
    }
  }
}
</script>

<style lang="scss">
#page-title {
  margin-bottom: 40px;
}

.card {
  border-radius: 10px;
  border: none;
  box-shadow: 0 10px 20px rgba(0,0,0, 0.03);
}

.dot {
  height: 12px;
  width: 12px;
  border-radius: 50%;
  display: inline-block;
  margin-right: 10px;
}
</style>
